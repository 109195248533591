import { defineAsyncComponent } from 'vue'
export default {
  "admin-main-layout": defineAsyncComponent(() => import("/app/layouts/AdminMainLayout.vue").then(m => m.default || m)),
  "guest-layout": defineAsyncComponent(() => import("/app/layouts/GuestLayout.vue").then(m => m.default || m)),
  "home-layout": defineAsyncComponent(() => import("/app/layouts/HomeLayout.vue").then(m => m.default || m)),
  "homepage-layout": defineAsyncComponent(() => import("/app/layouts/HomepageLayout.vue").then(m => m.default || m)),
  "main-layout": defineAsyncComponent(() => import("/app/layouts/MainLayout.vue").then(m => m.default || m)),
  "no-left-drawer-layout": defineAsyncComponent(() => import("/app/layouts/NoLeftDrawerLayout.vue").then(m => m.default || m)),
  "store-layout": defineAsyncComponent(() => import("/app/layouts/StoreLayout.vue").then(m => m.default || m)),
  "partials-aside-container": defineAsyncComponent(() => import("/app/layouts/partials/AsideContainer.vue").then(m => m.default || m)),
  "partials-drawer-container": defineAsyncComponent(() => import("/app/layouts/partials/DrawerContainer.vue").then(m => m.default || m)),
  "partials-footer-container": defineAsyncComponent(() => import("/app/layouts/partials/FooterContainer.vue").then(m => m.default || m)),
  "partials-header-container": defineAsyncComponent(() => import("/app/layouts/partials/HeaderContainer.vue").then(m => m.default || m)),
  "partials-layout-container": defineAsyncComponent(() => import("/app/layouts/partials/LayoutContainer.vue").then(m => m.default || m)),
  "partials-layout-content": defineAsyncComponent(() => import("/app/layouts/partials/LayoutContent.vue").then(m => m.default || m)),
  "partials-main-container": defineAsyncComponent(() => import("/app/layouts/partials/MainContainer.vue").then(m => m.default || m))
}