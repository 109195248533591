export const useOFetchCustom = (
    url: string,
    withoutAbortPrevRequest: boolean = false
): {
    data: Ref<any>
    error: Ref<null | Error>
    loading: Ref<boolean>
    setUrl: (newUrl: string) => void
    post: (
        body?: any,
        options?: {
            headers?: Record<string, string | number>
            onSuccess?: (res: any) => void
            onError?: (err: any) => void
            onFinish?: () => void
        }
    ) => Promise<void>
    get: (
        params?: any,
        options?: {
            headers?: Record<string, string | number>
            onSuccess?: (res: any) => void
            onError?: (err: any) => void
            onFinish?: () => void
        }
    ) => Promise<void>
    put: (
        body?: any,
        options?: {
            headers?: Record<string, string | number>
            onSuccess?: (res: any) => void
            onError?: (err: any) => void
            onFinish?: () => void
        }
    ) => Promise<void>
    destroy: (
        body?: any,
        options?: {
            onSuccess?: (res: any) => void
            onError?: (err: any) => void
            onFinish?: () => void
        }
    ) => Promise<void>
} => {
    const urlRef = ref(url)
    const data = ref(null)
    const error = ref(null)
    const loading = ref(false)

    const finalUrl = computed(() => "/api/" + urlRef.value)

    const client = useSanctumClient()
    const abortStore = useAbortControllerStore()
    function setUrl(newUrl: string) {
        urlRef.value = newUrl
    }

    const f = (
        options: Object,
        extraOptions?: {
            onSuccess?: (res: any) => void
            onError?: (err: any) => void
            onFinish?: () => void
        }
    ) => {
        loading.value = true
        return client(finalUrl.value, {
            ...options,
            signal: !withoutAbortPrevRequest
                ? abortStore.addController(finalUrl.value).signal
                : undefined,

            onResponseError: ({ response }) => {
                error.value = response
                if (extraOptions?.onError != undefined) {
                    extraOptions?.onError(response)
                }
            },
            retry: 1,
        })
            .then((res: any) => {
                data.value = res
                new Promise(() => {
                    if (extraOptions?.onSuccess != undefined) {
                        extraOptions?.onSuccess(res)
                    }
                })
                return res
            })
            .catch((e) => {
                new Promise(() => {
                    if (extraOptions?.onError != undefined) {
                        extraOptions?.onError(e)
                    }
                })
            })
            .finally(() => {
                loading.value = false
                new Promise(() => {
                    if (extraOptions?.onFinish != undefined) {
                        extraOptions?.onFinish()
                    }
                })
            })
    }

    async function post(
        body?: any,
        options?: {
            onSuccess?: (res: any) => void
            onError?: (err: any) => void
            onFinish?: () => void
            headers?: Record<string, string | number>
        }
    ) {
        f(
            {
                method: "POST",
                body: body,
                withCredentials: true,
                headers: options?.headers,
            },
            options
        ).then(() => {})
    }

    async function get(
        params?: any,
        options?: {
            onSuccess?: (res: any) => void
            onError?: (err: any) => void
            onFinish?: () => void
            headers?: Record<string, string | number>
        }
    ) {
        return f(
            {
                method: "GET",
                params: params,
                withCredentials: true,
                headers: options?.headers,
            },
            options
        )
    }

    async function put(
        body?: any,
        options?: {
            onSuccess?: (res: any) => void
            onError?: (err: any) => void
            onFinish?: () => void
            headers?: Record<string, string | number>
        }
    ) {
        f(
            {
                method: "PUT",
                body: body,
                withCredentials: true,
                headers: options?.headers,
            },
            options
        ).then(() => {})
    }

    function destroy(
        body?: any,
        options?: {
            onSuccess?: (res: any) => void
            onError?: (err: any) => void
            onFinish?: () => void
            headers?: Record<string, string | number>
        }
    ) {
        f(
            {
                method: "DELETE",
                body: body,
                withCredentials: true,
                headers: options?.headers,
            },
            options
        ).then(() => {})
    }

    return { data, error, loading, setUrl, post, get, put, destroy }
}
