import type { User } from "~/models/User"
import { inferRouteParams } from "~/common/helperRoutes"
import { listOfNotProtectedRoutes } from "~/common/routes"

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (listOfNotProtectedRoutes.includes(to.path)) {
        return
    }
    const user = useSanctumUser<User>()
    const permission = inferRouteParams(to.path, to.params)
    let autorize = false
    if (permission.length > 0) {
        autorize = permission.every((element) =>
            user.value?.all_permissions.includes(element)
        )
        if (!autorize) {
            return navigateTo("/404")
        }
    }

    return
})
