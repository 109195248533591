export default defineNuxtRouteMiddleware(async (to, from) => {
    const abortStore = useAbortControllerStore()
    abortStore.abortAllExceptLast()
    // const config = useRuntimeConfig()
    // const laravel_session = useCookie('laravel_session')

    // const finalUrl = config.public.TIODOMIN_API_BASE_URL + '/start-session'

    // const c = await $fetch.raw(finalUrl, {method: 'POST'})
    // laravel_session.value = c.headers.getSetCookie()[0]
    // console.log(laravel_session.value, 'laravel_session')
})
