<script setup lang="ts">
import { useOFetchCustom } from "./composables/useOFetchCustom"

const { data: favicon, get: fetchFavIcon } = useOFetchCustom("settings/favicon")

onMounted(() => fetchFavIcon())
</script>

<template>
    <div>
        <head>
            <link rel="icon" type="image/x-icon" :href="favicon" />
        </head>
        <NuxtLayout>
            <NuxtPage :key="(route) => route.path" />
        </NuxtLayout>
    </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.5s;
}
.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(1rem);
}

.layout-enter-active,
.layout-leave-active {
    transition: all 0.5s;
}
.layout-enter-from,
.layout-leave-to {
    filter: blur(1rem);
}
</style>
