import type { ShopCart } from "~/models/ShopCart"

export const useShopCartStore = defineStore("shop_cart_store", {
    state: (): {
        shopCart: ShopCart
        loadingShopCart: boolean
        cartCount: number | null
    } => ({
        shopCart: {
            items: [],
            count: 0,
            subtotal: 0,
            subtotal_formatted: "",
            shipment_formatted: "",
            to_pay_formatted: "",
            tax_formatted: "",
            is_wholesaler: false,
        },
        loadingShopCart: false,
        cartCount: null,
    }),
    actions: {
        async clearCart() {
            this.shopCart = {
                items: [],
                count: 0,
                subtotal: 0,
                subtotal_formatted: "",
                shipment_formatted: "",
                to_pay_formatted: "",
                tax_formatted: "",
                is_wholesaler: false,
            }
            this.cartCount = 0
        },
        async fetchShopCart(
            headers?: Record<string, string | number>,
            onlyFetch?: boolean
        ) {
            if (!onlyFetch) {
                await this.cartItemsCount()
            }
            this.loadingShopCart = true

            if ((this.cartCount && this.cartCount > 0) || onlyFetch) {
                await this.updateShopCartData(headers)
            }
            this.loadingShopCart = false
        },
        async updateShopCartData(headers?: Record<string, string | number>) {
            const { get: fetchCart } = useOFetchCustom("shop-cart-data", true)
            await fetchCart(undefined, {
                headers: headers,
                onSuccess: ({ data }) => {
                    this.shopCart = data
                },
                onError: (e) => {
                    console.log(e)
                },
            })
        },
        async cartItemsCount() {
            const { get: fetchCount } = useOFetchCustom("cart/count", true)

            await fetchCount(undefined, {
                onSuccess: (data) => {
                    this.cartCount = data?.count
                },
                onError: (error) => {
                    console.error(
                        "Error al obtener el conteo del carrito:",
                        error
                    )
                    this.cartCount = 0
                },
            })
        },
        async addToCart(body: any): Promise<any> {
            const { post } = useOFetchCustom("cart")

            return new Promise((resolve, reject) => {
                post(body, {
                    onSuccess: (res: any) => {
                        this.updateShopCartData()
                        this.cartItemsCount()
                        resolve(res)
                    },
                    onError: (error: any) => {
                        reject(error)
                    },
                })
            })
        },
        removeToCart() {
            this.fetchShopCart(undefined, true)
        },
    },
})
