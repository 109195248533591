import { PERMISSIONS } from "~/common/permissions"

export const listOfNotProtectedRoutes = [
    "/",
    "/store",
    "/store/[store]",
    "/offers",
    "/auth/login",
    "/auth/register",
    "/auth/resetpassword",
    "/auth/forgotpassword",
    "/auth/verifyemail",
    "/auth/confirmPassword",
    "/admin/orders/[order]/show",
]

export const listOfProtectedRoutes = {
    "/admin/users": [PERMISSIONS.READ_USERS],
    "/admin/users/create": [PERMISSIONS.CREATE_USERS],
    "/admin/roles": [PERMISSIONS.READ_ROLES],
    "/admin/roles/create": [PERMISSIONS.CREATE_ROLES],
    "/admin/offers": [PERMISSIONS.READ_OFFERS],
    "/admin/offers/create": [PERMISSIONS.CREATE_OFFERS],
    "/admin/products/create": [PERMISSIONS.CREATE_PRODUCTS],
    "/admin/products/list": [PERMISSIONS.READ_PRODUCTS],
    "/admin/orders": [PERMISSIONS.READ_ORDERS],
    "/admin/invoices": [PERMISSIONS.READ_INVOICES],
    "/admin/categories/create": [PERMISSIONS.CREATE_CATEGORIES],
    "/admin/categories": [PERMISSIONS.READ_CATEGORIES],
    "/admin/settings/customization":[PERMISSIONS.UPDATE_SETTINGS]
}

export const listOfProtectedWithParamsRoutes = {
    "/admin/users/[id]": [PERMISSIONS.UPDATE_USERS],
    "/admin/roles/[id]": [PERMISSIONS.UPDATE_ROLES],
    "/admin/offers/[id]": [PERMISSIONS.UPDATE_OFFERS],
    "/admin/products/[slug]/edit": [PERMISSIONS.UPDATE_PRODUCTS],
    "/admin/categories/[id]": [PERMISSIONS.UPDATE_CATEGORIES],
}
