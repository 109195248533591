import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import admin_45global from "/app/middleware/admin.global.ts";
import inactivity_45global from "/app/middleware/inactivity.global.ts";
import session_45global from "/app/middleware/session.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  admin_45global,
  inactivity_45global,
  session_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-create-product": () => import("/app/middleware/checkCreateProduct.ts"),
  "check-customization-settings": () => import("/app/middleware/checkCustomizationSettings.ts"),
  "check-edit-coin": () => import("/app/middleware/checkEditCoin.ts"),
  "check-edit-product": () => import("/app/middleware/checkEditProduct.ts"),
  "check-notifications-settings": () => import("/app/middleware/checkNotificationsSettings.ts"),
  "check-order-owner": () => import("/app/middleware/checkOrderOwner.ts"),
  "sanctum:auth": () => import("/app/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.js"),
  "sanctum:guest": () => import("/app/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.js")
}