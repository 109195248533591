
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as _404STh7p7T7N0Meta } from "/app/pages/404.vue?macro=true";
import { default as LoginPNft32ify6Meta } from "/app/pages/Admin/Analytics/Login.vue?macro=true";
import { default as MostSoldFEQgNgJ1ulMeta } from "/app/pages/Admin/Analytics/MostSold.vue?macro=true";
import { default as AccessesTYUXdBrR1wMeta } from "/app/pages/Admin/Analytics/Partials/Accesses.vue?macro=true";
import { default as NewUsers6Ry0dcc95lMeta } from "/app/pages/Admin/Analytics/Partials/NewUsers.vue?macro=true";
import { default as PagesMostVisitedvXf4Kf9FedMeta } from "/app/pages/Admin/Analytics/Partials/PagesMostVisited.vue?macro=true";
import { default as ProductsMostSoldNruRXfsVcrMeta } from "/app/pages/Admin/Analytics/Partials/ProductsMostSold.vue?macro=true";
import { default as ProductsMostVisitedV14LGAhzeZMeta } from "/app/pages/Admin/Analytics/Partials/ProductsMostVisited.vue?macro=true";
import { default as SalesBehaviouri6f6HUtvSVMeta } from "/app/pages/Admin/Analytics/Partials/SalesBehaviour.vue?macro=true";
import { default as SalesqckfKg7Ia1Meta } from "/app/pages/Admin/Analytics/Sales.vue?macro=true";
import { default as VisitsdezBNO4FqoMeta } from "/app/pages/Admin/Analytics/Visits.vue?macro=true";
import { default as _91id_93GM8M1sb82uMeta } from "/app/pages/Admin/banners/[id].vue?macro=true";
import { default as createTls6y8EBPFMeta } from "/app/pages/Admin/banners/create.vue?macro=true";
import { default as indexYmsD1urMbUMeta } from "/app/pages/Admin/banners/index.vue?macro=true";
import { default as _91id_93SnzDCQ1cOGMeta } from "/app/pages/Admin/blogs/categories/[id].vue?macro=true";
import { default as createYRZECtAMNaMeta } from "/app/pages/Admin/blogs/categories/create.vue?macro=true";
import { default as indexQiYL4YKgzaMeta } from "/app/pages/Admin/blogs/categories/index.vue?macro=true";
import { default as _91id_93U2WZs7fGr8Meta } from "/app/pages/Admin/blogs/keywords/[id].vue?macro=true";
import { default as createLlPvADp5yRMeta } from "/app/pages/Admin/blogs/keywords/create.vue?macro=true";
import { default as indexAVX8f0bSdeMeta } from "/app/pages/Admin/blogs/keywords/index.vue?macro=true";
import { default as _91id_93EX7OeoOdp0Meta } from "/app/pages/Admin/blogs/posts/[id].vue?macro=true";
import { default as createefcvwkDmCHMeta } from "/app/pages/Admin/blogs/posts/create.vue?macro=true";
import { default as indexN09f59yln3Meta } from "/app/pages/Admin/blogs/posts/index.vue?macro=true";
import { default as _91id_93XdnlzqpHGSMeta } from "/app/pages/Admin/brands/[id].vue?macro=true";
import { default as createEPaShWGnvSMeta } from "/app/pages/Admin/brands/create.vue?macro=true";
import { default as indexQOmRV7n9r7Meta } from "/app/pages/Admin/brands/index.vue?macro=true";
import { default as _91id_93FiS6rtij9oMeta } from "/app/pages/Admin/Categories/[id].vue?macro=true";
import { default as Createy5ertPuU9HMeta } from "/app/pages/Admin/Categories/Create.vue?macro=true";
import { default as indexCJxNqO0wAkMeta } from "/app/pages/Admin/Categories/index.vue?macro=true";
import { default as _91id_93m1RBo2FK5TMeta } from "/app/pages/Admin/Coins/[id].vue?macro=true";
import { default as createGRxj1E4j2cMeta } from "/app/pages/Admin/Coins/create.vue?macro=true";
import { default as indexOSU4kE50yFMeta } from "/app/pages/Admin/Coins/index.vue?macro=true";
import { default as _91id_93T3to6VF3CEMeta } from "/app/pages/Admin/combos/[id].vue?macro=true";
import { default as create8MQ4KQuRtDMeta } from "/app/pages/Admin/combos/create.vue?macro=true";
import { default as indexe2XqlOX6r6Meta } from "/app/pages/Admin/combos/index.vue?macro=true";
import { default as indexstZ9jQDbVDMeta } from "/app/pages/Admin/Criterias/index.vue?macro=true";
import { default as createXMLwSouye3Meta } from "/app/pages/Admin/ExchangeRates/create.vue?macro=true";
import { default as CreateBatchfFRanyl9YeMeta } from "/app/pages/Admin/ExchangeRates/CreateBatch.vue?macro=true";
import { default as EditY721kGkcGrMeta } from "/app/pages/Admin/ExchangeRates/Edit.vue?macro=true";
import { default as indexf2ySEdaAAEMeta } from "/app/pages/Admin/ExchangeRates/index.vue?macro=true";
import { default as CreateEQ0uhB2KZIMeta } from "/app/pages/Admin/invoices/Create.vue?macro=true";
import { default as indexokFBc4IelZMeta } from "/app/pages/Admin/invoices/index.vue?macro=true";
import { default as _91id_93zjIayQjucRMeta } from "/app/pages/Admin/Offers/[id].vue?macro=true";
import { default as CreateAecd1lfXy0Meta } from "/app/pages/Admin/Offers/Create.vue?macro=true";
import { default as indexORYOOkfbB0Meta } from "/app/pages/Admin/Offers/index.vue?macro=true";
import { default as _91id_93RkvqAcSNprMeta } from "/app/pages/Admin/orders/[id].vue?macro=true";
import { default as showbtNV3hYdRvMeta } from "/app/pages/Admin/orders/[order]/show.vue?macro=true";
import { default as indexMbomQvWfm5Meta } from "/app/pages/Admin/orders/export/index.vue?macro=true";
import { default as indexAy7yYULjiBMeta } from "/app/pages/Admin/orders/index.vue?macro=true";
import { default as indexG46RaPd6uwMeta } from "/app/pages/Admin/point-of-sales/[id]/index.vue?macro=true";
import { default as shiftshXUwSF2fO7Meta } from "/app/pages/Admin/point-of-sales/[id]/shifts.vue?macro=true";
import { default as createOleyr3aTJcMeta } from "/app/pages/Admin/point-of-sales/create.vue?macro=true";
import { default as indexoVs5nLaieeMeta } from "/app/pages/Admin/point-of-sales/index.vue?macro=true";
import { default as editDbIXebXfRmMeta } from "/app/pages/Admin/Products/[slug]/edit.vue?macro=true";
import { default as Createa9jJ0lNzKVMeta } from "/app/pages/Admin/Products/Create.vue?macro=true";
import { default as listwCk7l7uW1NMeta } from "/app/pages/Admin/Products/list.vue?macro=true";
import { default as indexx25xWAaRtYMeta } from "/app/pages/Admin/Reports/catalog/index.vue?macro=true";
import { default as indexnO6jQIvzdGMeta } from "/app/pages/Admin/Reports/inventory/index.vue?macro=true";
import { default as indexTQz9YyYQlmMeta } from "/app/pages/Admin/Reports/movements/index.vue?macro=true";
import { default as indexq7inwkhLAHMeta } from "/app/pages/Admin/Reports/orders/index.vue?macro=true";
import { default as indexOjf2bs2i5SMeta } from "/app/pages/Admin/Reports/sale/index.vue?macro=true";
import { default as indexomt8U6507pMeta } from "/app/pages/Admin/Reports/salesaccounting/index.vue?macro=true";
import { default as indexMpkt3v4HlQMeta } from "/app/pages/Admin/Reports/soldProduct/index.vue?macro=true";
import { default as indexKY12jKZXr6Meta } from "/app/pages/Admin/Reports/unify_sale/index.vue?macro=true";
import { default as index81ld4DjSEBMeta } from "/app/pages/Admin/Reviews/index.vue?macro=true";
import { default as _91id_93KEqMeWsWLkMeta } from "/app/pages/Admin/Roles/[id].vue?macro=true";
import { default as CreateUzbSGGdDqWMeta } from "/app/pages/Admin/Roles/Create.vue?macro=true";
import { default as indexLTcmbj3RapMeta } from "/app/pages/Admin/Roles/index.vue?macro=true";
import { default as CustomizationnLhrkJOvBDMeta } from "/app/pages/Admin/Settings/Customization.vue?macro=true";
import { default as GeneralsItpbOsixYkMeta } from "/app/pages/Admin/Settings/Generals.vue?macro=true";
import { default as ContactsPdlCe1LG9tMeta } from "/app/pages/Admin/Settings/Partials/Contacts.vue?macro=true";
import { default as FrequentAsks1zNUVSP1YCMeta } from "/app/pages/Admin/Settings/Partials/FrequentAsks.vue?macro=true";
import { default as InformationSectionmVQhm4IdrYMeta } from "/app/pages/Admin/Settings/Partials/InformationSection.vue?macro=true";
import { default as LocalPickupx5Ir9PzLbwMeta } from "/app/pages/Admin/Settings/Partials/LocalPickup.vue?macro=true";
import { default as MiscfmuAl96ffZMeta } from "/app/pages/Admin/Settings/Partials/Misc.vue?macro=true";
import { default as Miscellaneous7r4v2k1k7BMeta } from "/app/pages/Admin/Settings/Partials/Miscellaneous.vue?macro=true";
import { default as NotificationsizpvvmTDFhMeta } from "/app/pages/Admin/Settings/Partials/Notifications.vue?macro=true";
import { default as PayFormItemyPc1HWnjE4Meta } from "/app/pages/Admin/Settings/Partials/PayFormItem.vue?macro=true";
import { default as PayFormsVQP8xaRmlLMeta } from "/app/pages/Admin/Settings/Partials/PayForms.vue?macro=true";
import { default as ProductsInventarynvNPKUC5E1Meta } from "/app/pages/Admin/Settings/Partials/ProductsInventary.vue?macro=true";
import { default as RelatedProductStrategiesNpMmSxg8fTMeta } from "/app/pages/Admin/Settings/Partials/RelatedProductStrategies.vue?macro=true";
import { default as ScheduleFormi98cOEu9fKMeta } from "/app/pages/Admin/Settings/Partials/ScheduleForm.vue?macro=true";
import { default as ScheduleStore9Mh60neDHPMeta } from "/app/pages/Admin/Settings/Partials/ScheduleStore.vue?macro=true";
import { default as Shipments8p58v7ILyMeta } from "/app/pages/Admin/Settings/Partials/Shipment.vue?macro=true";
import { default as ShipmentProvinceV0EcrkENSBMeta } from "/app/pages/Admin/Settings/Partials/ShipmentProvince.vue?macro=true";
import { default as ShipmentSelectLocationsdKECALhVmjMeta } from "/app/pages/Admin/Settings/Partials/ShipmentSelectLocations.vue?macro=true";
import { default as ShipmentUnitRangeNvBr9AyhqqMeta } from "/app/pages/Admin/Settings/Partials/ShipmentUnitRange.vue?macro=true";
import { default as ShoppingCard2DZe2GXxMDMeta } from "/app/pages/Admin/Settings/Partials/ShoppingCard.vue?macro=true";
import { default as TwiceTimestTdo08b1VMeta } from "/app/pages/Admin/Settings/Partials/TwiceTime.vue?macro=true";
import { default as _91id_934xJEBFcCbGMeta } from "/app/pages/Admin/testimonials/[id].vue?macro=true";
import { default as create2qLNjVzyyGMeta } from "/app/pages/Admin/testimonials/create.vue?macro=true";
import { default as indexdThF4OK0BdMeta } from "/app/pages/Admin/testimonials/index.vue?macro=true";
import { default as _91id_93vDNpOuaA6pMeta } from "/app/pages/Admin/Users/[id].vue?macro=true";
import { default as create9E3IcyrukmMeta } from "/app/pages/Admin/Users/create.vue?macro=true";
import { default as indexE5cTrmppiJMeta } from "/app/pages/Admin/Users/index.vue?macro=true";
import { default as ConfirmPasswordra4JnFDaTWMeta } from "/app/pages/Auth/ConfirmPassword.vue?macro=true";
import { default as ForgotPasswordCDue6KKurYMeta } from "/app/pages/Auth/ForgotPassword.vue?macro=true";
import { default as LoginekOdCTVoFyMeta } from "/app/pages/Auth/Login.vue?macro=true";
import { default as Registery2oeogxvmHMeta } from "/app/pages/Auth/Register.vue?macro=true";
import { default as ResetPasswordLAGYHoKH4rMeta } from "/app/pages/Auth/ResetPassword.vue?macro=true";
import { default as VerifyEmailErFhC3WKjYMeta } from "/app/pages/Auth/VerifyEmail.vue?macro=true";
import { default as _91blog_93SLRfK6x4QJMeta } from "/app/pages/blog/[blog].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as indexSZk2qNn8VwMeta } from "/app/pages/Cart/index.vue?macro=true";
import { default as _91encoded_93Q90RpUzOQdMeta } from "/app/pages/Cart/share-cart/[encoded].vue?macro=true";
import { default as indexS7GVjoY7GdMeta } from "/app/pages/Contact-Us/index.vue?macro=true";
import { default as IndexpuUTymrk5kMeta } from "/app/pages/Distribution/Index.vue?macro=true";
import { default as CreatenbylKJgQJRMeta } from "/app/pages/ExchangeRates/Create.vue?macro=true";
import { default as CreateBatchuYiDtqjk1fMeta } from "/app/pages/ExchangeRates/CreateBatch.vue?macro=true";
import { default as EditXlQb9IpFYxMeta } from "/app/pages/ExchangeRates/Edit.vue?macro=true";
import { default as Index8JYc54ak3TMeta } from "/app/pages/ExchangeRates/Index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as infoRUNMKy7QPmMeta } from "/app/pages/info.vue?macro=true";
import { default as _91InformationPage_93WZq69kjGVSMeta } from "/app/pages/information/[InformationPage].vue?macro=true";
import { default as indexOPPBcA3NliMeta } from "/app/pages/Offers/index.vue?macro=true";
import { default as redirect_45pageXEsqY8trshMeta } from "/app/pages/orders/[order]/redirect-page.vue?macro=true";
import { default as show7MzI1W3uSxMeta } from "/app/pages/orders/[order]/show.vue?macro=true";
import { default as success_45page1KTJJ1TLmfMeta } from "/app/pages/orders/[order]/success-page.vue?macro=true";
import { default as _91product_93fyC6SDu9TAMeta } from "/app/pages/Products/[product].vue?macro=true";
import { default as editJpj8E8UponMeta } from "/app/pages/Products/[slug]/edit.vue?macro=true";
import { default as CreateDQA0mG28MSMeta } from "/app/pages/Products/Create.vue?macro=true";
import { default as IndexH4UCbZpHgGMeta } from "/app/pages/Products/Index.vue?macro=true";
import { default as _91product_93OmygPy1iPjMeta } from "/app/pages/Products/Review/Read/[product].vue?macro=true";
import { default as _91product_937uvvlCEDhtMeta } from "/app/pages/Products/Review/Write/[product].vue?macro=true";
import { default as SuccessPage46UFPgmeHDMeta } from "/app/pages/Products/SuccessPage.vue?macro=true";
import { default as indexpUrUeYrGt9Meta } from "/app/pages/Profile/Beneficiaries/index.vue?macro=true";
import { default as indexNBnl2sgmW2Meta } from "/app/pages/Profile/index.vue?macro=true";
import { default as indexw7wf9I4RT0Meta } from "/app/pages/Profile/Orders/index.vue?macro=true";
import { default as indexDmh9fPzI0hMeta } from "/app/pages/return-policy/index.vue?macro=true";
import { default as indexmjvIssl8TKMeta } from "/app/pages/shipment-policy/index.vue?macro=true";
import { default as index9hk6qvvq7BMeta } from "/app/pages/store/index.vue?macro=true";
import { default as indexWs7cx4wIYsMeta } from "/app/pages/terms-conditions/index.vue?macro=true";
import { default as indexEpaZLKjBmcMeta } from "/app/pages/warranty-policy/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/app/pages/404.vue")
  },
  {
    name: "Admin-Analytics-Login",
    path: "/Admin/Analytics/Login",
    component: () => import("/app/pages/Admin/Analytics/Login.vue")
  },
  {
    name: "Admin-Analytics-MostSold",
    path: "/Admin/Analytics/MostSold",
    meta: MostSoldFEQgNgJ1ulMeta || {},
    component: () => import("/app/pages/Admin/Analytics/MostSold.vue")
  },
  {
    name: "Admin-Analytics-Partials-Accesses",
    path: "/Admin/Analytics/Partials/Accesses",
    component: () => import("/app/pages/Admin/Analytics/Partials/Accesses.vue")
  },
  {
    name: "Admin-Analytics-Partials-NewUsers",
    path: "/Admin/Analytics/Partials/NewUsers",
    component: () => import("/app/pages/Admin/Analytics/Partials/NewUsers.vue")
  },
  {
    name: "Admin-Analytics-Partials-PagesMostVisited",
    path: "/Admin/Analytics/Partials/PagesMostVisited",
    component: () => import("/app/pages/Admin/Analytics/Partials/PagesMostVisited.vue")
  },
  {
    name: "Admin-Analytics-Partials-ProductsMostSold",
    path: "/Admin/Analytics/Partials/ProductsMostSold",
    component: () => import("/app/pages/Admin/Analytics/Partials/ProductsMostSold.vue")
  },
  {
    name: "Admin-Analytics-Partials-ProductsMostVisited",
    path: "/Admin/Analytics/Partials/ProductsMostVisited",
    component: () => import("/app/pages/Admin/Analytics/Partials/ProductsMostVisited.vue")
  },
  {
    name: "Admin-Analytics-Partials-SalesBehaviour",
    path: "/Admin/Analytics/Partials/SalesBehaviour",
    component: () => import("/app/pages/Admin/Analytics/Partials/SalesBehaviour.vue")
  },
  {
    name: "Admin-Analytics-Sales",
    path: "/Admin/Analytics/Sales",
    meta: SalesqckfKg7Ia1Meta || {},
    component: () => import("/app/pages/Admin/Analytics/Sales.vue")
  },
  {
    name: "Admin-Analytics-Visits",
    path: "/Admin/Analytics/Visits",
    meta: VisitsdezBNO4FqoMeta || {},
    component: () => import("/app/pages/Admin/Analytics/Visits.vue")
  },
  {
    name: "Admin-banners-id",
    path: "/Admin/banners/:id()",
    meta: _91id_93GM8M1sb82uMeta || {},
    component: () => import("/app/pages/Admin/banners/[id].vue")
  },
  {
    name: "Admin-banners-create",
    path: "/Admin/banners/create",
    meta: createTls6y8EBPFMeta || {},
    component: () => import("/app/pages/Admin/banners/create.vue")
  },
  {
    name: "Admin-banners",
    path: "/Admin/banners",
    meta: indexYmsD1urMbUMeta || {},
    component: () => import("/app/pages/Admin/banners/index.vue")
  },
  {
    name: "Admin-blogs-categories-id",
    path: "/Admin/blogs/categories/:id()",
    meta: _91id_93SnzDCQ1cOGMeta || {},
    component: () => import("/app/pages/Admin/blogs/categories/[id].vue")
  },
  {
    name: "Admin-blogs-categories-create",
    path: "/Admin/blogs/categories/create",
    meta: createYRZECtAMNaMeta || {},
    component: () => import("/app/pages/Admin/blogs/categories/create.vue")
  },
  {
    name: "Admin-blogs-categories",
    path: "/Admin/blogs/categories",
    meta: indexQiYL4YKgzaMeta || {},
    component: () => import("/app/pages/Admin/blogs/categories/index.vue")
  },
  {
    name: "Admin-blogs-keywords-id",
    path: "/Admin/blogs/keywords/:id()",
    meta: _91id_93U2WZs7fGr8Meta || {},
    component: () => import("/app/pages/Admin/blogs/keywords/[id].vue")
  },
  {
    name: "Admin-blogs-keywords-create",
    path: "/Admin/blogs/keywords/create",
    meta: createLlPvADp5yRMeta || {},
    component: () => import("/app/pages/Admin/blogs/keywords/create.vue")
  },
  {
    name: "Admin-blogs-keywords",
    path: "/Admin/blogs/keywords",
    meta: indexAVX8f0bSdeMeta || {},
    component: () => import("/app/pages/Admin/blogs/keywords/index.vue")
  },
  {
    name: "Admin-blogs-posts-id",
    path: "/Admin/blogs/posts/:id()",
    meta: _91id_93EX7OeoOdp0Meta || {},
    component: () => import("/app/pages/Admin/blogs/posts/[id].vue")
  },
  {
    name: "Admin-blogs-posts-create",
    path: "/Admin/blogs/posts/create",
    meta: createefcvwkDmCHMeta || {},
    component: () => import("/app/pages/Admin/blogs/posts/create.vue")
  },
  {
    name: "Admin-blogs-posts",
    path: "/Admin/blogs/posts",
    meta: indexN09f59yln3Meta || {},
    component: () => import("/app/pages/Admin/blogs/posts/index.vue")
  },
  {
    name: "Admin-brands-id",
    path: "/Admin/brands/:id()",
    meta: _91id_93XdnlzqpHGSMeta || {},
    component: () => import("/app/pages/Admin/brands/[id].vue")
  },
  {
    name: "Admin-brands-create",
    path: "/Admin/brands/create",
    meta: createEPaShWGnvSMeta || {},
    component: () => import("/app/pages/Admin/brands/create.vue")
  },
  {
    name: "Admin-brands",
    path: "/Admin/brands",
    meta: indexQOmRV7n9r7Meta || {},
    component: () => import("/app/pages/Admin/brands/index.vue")
  },
  {
    name: "Admin-Categories-id",
    path: "/Admin/Categories/:id()",
    meta: _91id_93FiS6rtij9oMeta || {},
    component: () => import("/app/pages/Admin/Categories/[id].vue")
  },
  {
    name: "Admin-Categories-Create",
    path: "/Admin/Categories/Create",
    meta: Createy5ertPuU9HMeta || {},
    component: () => import("/app/pages/Admin/Categories/Create.vue")
  },
  {
    name: "Admin-Categories",
    path: "/Admin/Categories",
    meta: indexCJxNqO0wAkMeta || {},
    component: () => import("/app/pages/Admin/Categories/index.vue")
  },
  {
    name: "Admin-Coins-id",
    path: "/Admin/Coins/:id()",
    meta: _91id_93m1RBo2FK5TMeta || {},
    component: () => import("/app/pages/Admin/Coins/[id].vue")
  },
  {
    name: "Admin-Coins-create",
    path: "/Admin/Coins/create",
    meta: createGRxj1E4j2cMeta || {},
    component: () => import("/app/pages/Admin/Coins/create.vue")
  },
  {
    name: "Admin-Coins",
    path: "/Admin/Coins",
    meta: indexOSU4kE50yFMeta || {},
    component: () => import("/app/pages/Admin/Coins/index.vue")
  },
  {
    name: "Admin-combos-id",
    path: "/Admin/combos/:id()",
    meta: _91id_93T3to6VF3CEMeta || {},
    component: () => import("/app/pages/Admin/combos/[id].vue")
  },
  {
    name: "Admin-combos-create",
    path: "/Admin/combos/create",
    meta: create8MQ4KQuRtDMeta || {},
    component: () => import("/app/pages/Admin/combos/create.vue")
  },
  {
    name: "Admin-combos",
    path: "/Admin/combos",
    meta: indexe2XqlOX6r6Meta || {},
    component: () => import("/app/pages/Admin/combos/index.vue")
  },
  {
    name: "Admin-Criterias",
    path: "/Admin/Criterias",
    meta: indexstZ9jQDbVDMeta || {},
    component: () => import("/app/pages/Admin/Criterias/index.vue")
  },
  {
    name: "Admin-ExchangeRates-create",
    path: "/Admin/ExchangeRates/create",
    meta: createXMLwSouye3Meta || {},
    component: () => import("/app/pages/Admin/ExchangeRates/create.vue")
  },
  {
    name: "Admin-ExchangeRates-CreateBatch",
    path: "/Admin/ExchangeRates/CreateBatch",
    component: () => import("/app/pages/Admin/ExchangeRates/CreateBatch.vue")
  },
  {
    name: "Admin-ExchangeRates-Edit",
    path: "/Admin/ExchangeRates/Edit",
    component: () => import("/app/pages/Admin/ExchangeRates/Edit.vue")
  },
  {
    name: "Admin-ExchangeRates",
    path: "/Admin/ExchangeRates",
    meta: indexf2ySEdaAAEMeta || {},
    component: () => import("/app/pages/Admin/ExchangeRates/index.vue")
  },
  {
    name: "Admin-invoices-Create",
    path: "/Admin/invoices/Create",
    meta: CreateEQ0uhB2KZIMeta || {},
    component: () => import("/app/pages/Admin/invoices/Create.vue")
  },
  {
    name: "Admin-invoices",
    path: "/Admin/invoices",
    meta: indexokFBc4IelZMeta || {},
    component: () => import("/app/pages/Admin/invoices/index.vue")
  },
  {
    name: "Admin-Offers-id",
    path: "/Admin/Offers/:id()",
    meta: _91id_93zjIayQjucRMeta || {},
    component: () => import("/app/pages/Admin/Offers/[id].vue")
  },
  {
    name: "Admin-Offers-Create",
    path: "/Admin/Offers/Create",
    meta: CreateAecd1lfXy0Meta || {},
    component: () => import("/app/pages/Admin/Offers/Create.vue")
  },
  {
    name: "Admin-Offers",
    path: "/Admin/Offers",
    meta: indexORYOOkfbB0Meta || {},
    component: () => import("/app/pages/Admin/Offers/index.vue")
  },
  {
    name: "Admin-orders-id",
    path: "/Admin/orders/:id()",
    component: () => import("/app/pages/Admin/orders/[id].vue")
  },
  {
    name: "Admin-orders-order-show",
    path: "/Admin/orders/:order()/show",
    meta: showbtNV3hYdRvMeta || {},
    component: () => import("/app/pages/Admin/orders/[order]/show.vue")
  },
  {
    name: "Admin-orders-export",
    path: "/Admin/orders/export",
    meta: indexMbomQvWfm5Meta || {},
    component: () => import("/app/pages/Admin/orders/export/index.vue")
  },
  {
    name: "Admin-orders",
    path: "/Admin/orders",
    meta: indexAy7yYULjiBMeta || {},
    component: () => import("/app/pages/Admin/orders/index.vue")
  },
  {
    name: "Admin-point-of-sales-id",
    path: "/Admin/point-of-sales/:id()",
    meta: indexG46RaPd6uwMeta || {},
    component: () => import("/app/pages/Admin/point-of-sales/[id]/index.vue")
  },
  {
    name: "Admin-point-of-sales-id-shifts",
    path: "/Admin/point-of-sales/:id()/shifts",
    meta: shiftshXUwSF2fO7Meta || {},
    component: () => import("/app/pages/Admin/point-of-sales/[id]/shifts.vue")
  },
  {
    name: "Admin-point-of-sales-create",
    path: "/Admin/point-of-sales/create",
    meta: createOleyr3aTJcMeta || {},
    component: () => import("/app/pages/Admin/point-of-sales/create.vue")
  },
  {
    name: "Admin-point-of-sales",
    path: "/Admin/point-of-sales",
    meta: indexoVs5nLaieeMeta || {},
    component: () => import("/app/pages/Admin/point-of-sales/index.vue")
  },
  {
    name: "Admin-Products-slug-edit",
    path: "/Admin/Products/:slug()/edit",
    meta: editDbIXebXfRmMeta || {},
    component: () => import("/app/pages/Admin/Products/[slug]/edit.vue")
  },
  {
    name: "Admin-Products-Create",
    path: "/Admin/Products/Create",
    meta: Createa9jJ0lNzKVMeta || {},
    component: () => import("/app/pages/Admin/Products/Create.vue")
  },
  {
    name: "Admin-Products-list",
    path: "/Admin/Products/list",
    meta: listwCk7l7uW1NMeta || {},
    component: () => import("/app/pages/Admin/Products/list.vue")
  },
  {
    name: "Admin-Reports-catalog",
    path: "/Admin/Reports/catalog",
    meta: indexx25xWAaRtYMeta || {},
    component: () => import("/app/pages/Admin/Reports/catalog/index.vue")
  },
  {
    name: "Admin-Reports-inventory",
    path: "/Admin/Reports/inventory",
    meta: indexnO6jQIvzdGMeta || {},
    component: () => import("/app/pages/Admin/Reports/inventory/index.vue")
  },
  {
    name: "Admin-Reports-movements",
    path: "/Admin/Reports/movements",
    meta: indexTQz9YyYQlmMeta || {},
    component: () => import("/app/pages/Admin/Reports/movements/index.vue")
  },
  {
    name: "Admin-Reports-orders",
    path: "/Admin/Reports/orders",
    meta: indexq7inwkhLAHMeta || {},
    component: () => import("/app/pages/Admin/Reports/orders/index.vue")
  },
  {
    name: "Admin-Reports-sale",
    path: "/Admin/Reports/sale",
    meta: indexOjf2bs2i5SMeta || {},
    component: () => import("/app/pages/Admin/Reports/sale/index.vue")
  },
  {
    name: "Admin-Reports-salesaccounting",
    path: "/Admin/Reports/salesaccounting",
    meta: indexomt8U6507pMeta || {},
    component: () => import("/app/pages/Admin/Reports/salesaccounting/index.vue")
  },
  {
    name: "Admin-Reports-soldProduct",
    path: "/Admin/Reports/soldProduct",
    meta: indexMpkt3v4HlQMeta || {},
    component: () => import("/app/pages/Admin/Reports/soldProduct/index.vue")
  },
  {
    name: "Admin-Reports-unify_sale",
    path: "/Admin/Reports/unify_sale",
    meta: indexKY12jKZXr6Meta || {},
    component: () => import("/app/pages/Admin/Reports/unify_sale/index.vue")
  },
  {
    name: "Admin-Reviews",
    path: "/Admin/Reviews",
    meta: index81ld4DjSEBMeta || {},
    component: () => import("/app/pages/Admin/Reviews/index.vue")
  },
  {
    name: "Admin-Roles-id",
    path: "/Admin/Roles/:id()",
    meta: _91id_93KEqMeWsWLkMeta || {},
    component: () => import("/app/pages/Admin/Roles/[id].vue")
  },
  {
    name: "Admin-Roles-Create",
    path: "/Admin/Roles/Create",
    meta: CreateUzbSGGdDqWMeta || {},
    component: () => import("/app/pages/Admin/Roles/Create.vue")
  },
  {
    name: "Admin-Roles",
    path: "/Admin/Roles",
    meta: indexLTcmbj3RapMeta || {},
    component: () => import("/app/pages/Admin/Roles/index.vue")
  },
  {
    name: "Admin-Settings-Customization",
    path: "/Admin/Settings/Customization",
    meta: CustomizationnLhrkJOvBDMeta || {},
    component: () => import("/app/pages/Admin/Settings/Customization.vue")
  },
  {
    name: "Admin-Settings-Generals",
    path: "/Admin/Settings/Generals",
    meta: GeneralsItpbOsixYkMeta || {},
    component: () => import("/app/pages/Admin/Settings/Generals.vue")
  },
  {
    name: "Admin-Settings-Partials-Contacts",
    path: "/Admin/Settings/Partials/Contacts",
    component: () => import("/app/pages/Admin/Settings/Partials/Contacts.vue")
  },
  {
    name: "Admin-Settings-Partials-FrequentAsks",
    path: "/Admin/Settings/Partials/FrequentAsks",
    component: () => import("/app/pages/Admin/Settings/Partials/FrequentAsks.vue")
  },
  {
    name: "Admin-Settings-Partials-InformationSection",
    path: "/Admin/Settings/Partials/InformationSection",
    component: () => import("/app/pages/Admin/Settings/Partials/InformationSection.vue")
  },
  {
    name: "Admin-Settings-Partials-LocalPickup",
    path: "/Admin/Settings/Partials/LocalPickup",
    component: () => import("/app/pages/Admin/Settings/Partials/LocalPickup.vue")
  },
  {
    name: "Admin-Settings-Partials-Misc",
    path: "/Admin/Settings/Partials/Misc",
    component: () => import("/app/pages/Admin/Settings/Partials/Misc.vue")
  },
  {
    name: "Admin-Settings-Partials-Miscellaneous",
    path: "/Admin/Settings/Partials/Miscellaneous",
    component: () => import("/app/pages/Admin/Settings/Partials/Miscellaneous.vue")
  },
  {
    name: "Admin-Settings-Partials-Notifications",
    path: "/Admin/Settings/Partials/Notifications",
    component: () => import("/app/pages/Admin/Settings/Partials/Notifications.vue")
  },
  {
    name: "Admin-Settings-Partials-PayFormItem",
    path: "/Admin/Settings/Partials/PayFormItem",
    component: () => import("/app/pages/Admin/Settings/Partials/PayFormItem.vue")
  },
  {
    name: "Admin-Settings-Partials-PayForms",
    path: "/Admin/Settings/Partials/PayForms",
    component: () => import("/app/pages/Admin/Settings/Partials/PayForms.vue")
  },
  {
    name: "Admin-Settings-Partials-ProductsInventary",
    path: "/Admin/Settings/Partials/ProductsInventary",
    component: () => import("/app/pages/Admin/Settings/Partials/ProductsInventary.vue")
  },
  {
    name: "Admin-Settings-Partials-RelatedProductStrategies",
    path: "/Admin/Settings/Partials/RelatedProductStrategies",
    component: () => import("/app/pages/Admin/Settings/Partials/RelatedProductStrategies.vue")
  },
  {
    name: "Admin-Settings-Partials-ScheduleForm",
    path: "/Admin/Settings/Partials/ScheduleForm",
    component: () => import("/app/pages/Admin/Settings/Partials/ScheduleForm.vue")
  },
  {
    name: "Admin-Settings-Partials-ScheduleStore",
    path: "/Admin/Settings/Partials/ScheduleStore",
    component: () => import("/app/pages/Admin/Settings/Partials/ScheduleStore.vue")
  },
  {
    name: "Admin-Settings-Partials-Shipment",
    path: "/Admin/Settings/Partials/Shipment",
    component: () => import("/app/pages/Admin/Settings/Partials/Shipment.vue")
  },
  {
    name: "Admin-Settings-Partials-ShipmentProvince",
    path: "/Admin/Settings/Partials/ShipmentProvince",
    component: () => import("/app/pages/Admin/Settings/Partials/ShipmentProvince.vue")
  },
  {
    name: "Admin-Settings-Partials-ShipmentSelectLocations",
    path: "/Admin/Settings/Partials/ShipmentSelectLocations",
    component: () => import("/app/pages/Admin/Settings/Partials/ShipmentSelectLocations.vue")
  },
  {
    name: "Admin-Settings-Partials-ShipmentUnitRange",
    path: "/Admin/Settings/Partials/ShipmentUnitRange",
    component: () => import("/app/pages/Admin/Settings/Partials/ShipmentUnitRange.vue")
  },
  {
    name: "Admin-Settings-Partials-ShoppingCard",
    path: "/Admin/Settings/Partials/ShoppingCard",
    component: () => import("/app/pages/Admin/Settings/Partials/ShoppingCard.vue")
  },
  {
    name: "Admin-Settings-Partials-TwiceTime",
    path: "/Admin/Settings/Partials/TwiceTime",
    component: () => import("/app/pages/Admin/Settings/Partials/TwiceTime.vue")
  },
  {
    name: "Admin-testimonials-id",
    path: "/Admin/testimonials/:id()",
    meta: _91id_934xJEBFcCbGMeta || {},
    component: () => import("/app/pages/Admin/testimonials/[id].vue")
  },
  {
    name: "Admin-testimonials-create",
    path: "/Admin/testimonials/create",
    meta: create2qLNjVzyyGMeta || {},
    component: () => import("/app/pages/Admin/testimonials/create.vue")
  },
  {
    name: "Admin-testimonials",
    path: "/Admin/testimonials",
    meta: indexdThF4OK0BdMeta || {},
    component: () => import("/app/pages/Admin/testimonials/index.vue")
  },
  {
    name: "Admin-Users-id",
    path: "/Admin/Users/:id()",
    meta: _91id_93vDNpOuaA6pMeta || {},
    component: () => import("/app/pages/Admin/Users/[id].vue")
  },
  {
    name: "Admin-Users-create",
    path: "/Admin/Users/create",
    meta: create9E3IcyrukmMeta || {},
    component: () => import("/app/pages/Admin/Users/create.vue")
  },
  {
    name: "Admin-Users",
    path: "/Admin/Users",
    meta: indexE5cTrmppiJMeta || {},
    component: () => import("/app/pages/Admin/Users/index.vue")
  },
  {
    name: "Auth-ConfirmPassword",
    path: "/Auth/ConfirmPassword",
    component: () => import("/app/pages/Auth/ConfirmPassword.vue")
  },
  {
    name: "Auth-ForgotPassword",
    path: "/Auth/ForgotPassword",
    component: () => import("/app/pages/Auth/ForgotPassword.vue")
  },
  {
    name: "Auth-Login",
    path: "/Auth/Login",
    meta: LoginekOdCTVoFyMeta || {},
    component: () => import("/app/pages/Auth/Login.vue")
  },
  {
    name: "Auth-Register",
    path: "/Auth/Register",
    component: () => import("/app/pages/Auth/Register.vue")
  },
  {
    name: "Auth-ResetPassword",
    path: "/Auth/ResetPassword",
    component: () => import("/app/pages/Auth/ResetPassword.vue")
  },
  {
    name: "Auth-VerifyEmail",
    path: "/Auth/VerifyEmail",
    component: () => import("/app/pages/Auth/VerifyEmail.vue")
  },
  {
    name: "blog-blog",
    path: "/blog/:blog()",
    meta: _91blog_93SLRfK6x4QJMeta || {},
    component: () => import("/app/pages/blog/[blog].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "Cart",
    path: "/Cart",
    component: () => import("/app/pages/Cart/index.vue")
  },
  {
    name: "Cart-share-cart-encoded",
    path: "/Cart/share-cart/:encoded()",
    component: () => import("/app/pages/Cart/share-cart/[encoded].vue")
  },
  {
    name: "Contact-Us",
    path: "/Contact-Us",
    component: () => import("/app/pages/Contact-Us/index.vue")
  },
  {
    name: "Distribution-Index",
    path: "/Distribution/Index",
    component: () => import("/app/pages/Distribution/Index.vue")
  },
  {
    name: "ExchangeRates-Create",
    path: "/ExchangeRates/Create",
    component: () => import("/app/pages/ExchangeRates/Create.vue")
  },
  {
    name: "ExchangeRates-CreateBatch",
    path: "/ExchangeRates/CreateBatch",
    component: () => import("/app/pages/ExchangeRates/CreateBatch.vue")
  },
  {
    name: "ExchangeRates-Edit",
    path: "/ExchangeRates/Edit",
    component: () => import("/app/pages/ExchangeRates/Edit.vue")
  },
  {
    name: "ExchangeRates-Index",
    path: "/ExchangeRates/Index",
    component: () => import("/app/pages/ExchangeRates/Index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/app/pages/info.vue")
  },
  {
    name: "information-InformationPage",
    path: "/information/:InformationPage()",
    component: () => import("/app/pages/information/[InformationPage].vue")
  },
  {
    name: "Offers",
    path: "/Offers",
    component: () => import("/app/pages/Offers/index.vue")
  },
  {
    name: "orders-order-redirect-page",
    path: "/orders/:order()/redirect-page",
    meta: redirect_45pageXEsqY8trshMeta || {},
    component: () => import("/app/pages/orders/[order]/redirect-page.vue")
  },
  {
    name: "orders-order-show",
    path: "/orders/:order()/show",
    meta: show7MzI1W3uSxMeta || {},
    component: () => import("/app/pages/orders/[order]/show.vue")
  },
  {
    name: "orders-order-success-page",
    path: "/orders/:order()/success-page",
    component: () => import("/app/pages/orders/[order]/success-page.vue")
  },
  {
    name: "Products-product",
    path: "/Products/:product()",
    meta: _91product_93fyC6SDu9TAMeta || {},
    component: () => import("/app/pages/Products/[product].vue")
  },
  {
    name: "Products-slug-edit",
    path: "/Products/:slug()/edit",
    component: () => import("/app/pages/Products/[slug]/edit.vue")
  },
  {
    name: "Products-Create",
    path: "/Products/Create",
    meta: CreateDQA0mG28MSMeta || {},
    component: () => import("/app/pages/Products/Create.vue")
  },
  {
    name: "Products-Index",
    path: "/Products/Index",
    meta: IndexH4UCbZpHgGMeta || {},
    component: () => import("/app/pages/Products/Index.vue")
  },
  {
    name: "Products-Review-Read-product",
    path: "/Products/Review/Read/:product()",
    component: () => import("/app/pages/Products/Review/Read/[product].vue")
  },
  {
    name: "Products-Review-Write-product",
    path: "/Products/Review/Write/:product()",
    component: () => import("/app/pages/Products/Review/Write/[product].vue")
  },
  {
    name: "Products-SuccessPage",
    path: "/Products/SuccessPage",
    component: () => import("/app/pages/Products/SuccessPage.vue")
  },
  {
    name: "Profile-Beneficiaries",
    path: "/Profile/Beneficiaries",
    meta: indexpUrUeYrGt9Meta || {},
    component: () => import("/app/pages/Profile/Beneficiaries/index.vue")
  },
  {
    name: "Profile",
    path: "/Profile",
    meta: indexNBnl2sgmW2Meta || {},
    component: () => import("/app/pages/Profile/index.vue")
  },
  {
    name: "Profile-Orders",
    path: "/Profile/Orders",
    meta: indexw7wf9I4RT0Meta || {},
    component: () => import("/app/pages/Profile/Orders/index.vue")
  },
  {
    name: "return-policy",
    path: "/return-policy",
    component: () => import("/app/pages/return-policy/index.vue")
  },
  {
    name: "shipment-policy",
    path: "/shipment-policy",
    component: () => import("/app/pages/shipment-policy/index.vue")
  },
  {
    name: "store",
    path: "/store",
    component: () => import("/app/pages/store/index.vue")
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    component: () => import("/app/pages/terms-conditions/index.vue")
  },
  {
    name: "warranty-policy",
    path: "/warranty-policy",
    component: () => import("/app/pages/warranty-policy/index.vue")
  }
]