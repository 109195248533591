export default defineNuxtRouteMiddleware(async () => {
    const { logout: lout } = useSanctumAuth()
    const shopCartStore = useShopCartStore()

    async function logout() {
        lout().then(() => {
            shopCartStore.clearCart()
            navigateTo("/", {})
            refreshNuxtData()
        })
    }

    const maxInactiveTime = 60 * 60 * 1000
    let lastActivityTime = Date.now()

    function updateLastActivity() {
        lastActivityTime = Date.now()
        localStorage.setItem("lastActivityTime", lastActivityTime.toString())
    }

    document.addEventListener("mousemove", updateLastActivity)
    document.addEventListener("keypress", updateLastActivity)
    document.addEventListener("click", updateLastActivity)
    document.addEventListener("scroll", updateLastActivity)

    const storedTime = localStorage.getItem("lastActivityTime")
    if (
        storedTime &&
        Date.now() - parseInt(storedTime, 10) >= maxInactiveTime
    ) {
        logout()
    }

    // Revisa la actividad periódicamente
    setInterval(() => {
        const currentTime = Date.now()
        if (currentTime - lastActivityTime >= maxInactiveTime) {
            logout()
        }
    },5 * 60 * 1000)
})
