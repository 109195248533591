export const useAbortControllerStore = defineStore("abortController", {
    state: (): {
        controller: AbortController
        controllers: Record<string, AbortController>
    } => ({
        controller: new AbortController(),
        controllers: {},
    }),
    actions: {
        executeAbort() {
            this.controller.abort()
        },
        abortAllExceptLast() {
            const entries = Object.entries(this.controllers)
            const size = entries.length

            entries.forEach(([key, value], index) => {
                if (index === size - 1) return

                console.log("abort controller with key", key)
                this.controllers[key].abort()
            })
        },
        addController(key: string) {
            const controller = new AbortController()
            if (key in this.controllers) {
                this.controllers[key].abort()
            }

            this.controllers[key] = controller

            return this.controllers[key] ?? controller
        },
    },
})
