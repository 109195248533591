import type { FetchContext } from "ofetch"
import type { ConsolaInstance } from "consola"
import Cookies from "js-cookie"

export default defineAppConfig({
    sanctum: {
        interceptors: {
            onRequest: async (
                app,
                ctx: FetchContext,
                logger: ConsolaInstance
            ) => {
                const token = Cookies.get("XSRF-TOKEN")
                if (token) {
                    const regex = /%3D|%25|%20|\d+/g
                    const cleanedToken = token
                        .replace(regex, "")
                        .replace("=", "")
                    Cookies.set("XSRF-TOKEN", cleanedToken)
                    ctx.options.headers.set("X-XSRF-TOKEN", cleanedToken)
                }
            },
            onResponse: async (
                app,
                ctx: FetchContext,
                logger: ConsolaInstance
            ) => {
                if (ctx.response?.status === 401) {
                    await navigateTo("/auth/login")
                }
            },
        },
    },
})
